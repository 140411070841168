import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Banner from 'components/banner'
import Block from 'components/block'
import ContactForm from 'components/contact-form'
import Image from 'components/image'
import Meta from 'components/meta'
import ProjectGrid from 'components/project-grid'
import Section from 'components/section'
import SocialLinks from 'components/social-links'
import Wrapper from 'components/wrapper'

interface Props {
  location: Location
}

const Index = ({ location }: Props) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
      <Wrapper location={location} className="w-100 mx-auto d-flex flex-column">
        <Meta />
        <Banner backgroundImage="adobe-19279258-green_2560x480.jpg">
          <div
            className="d-flex flex-column justify-content-evenly align-items-center"
            style={{ height: '33vh', minHeight: '260px' }}
          >
            <div>
              <img src="/eco-art-wm-stacked_175x200_o85.png" width="175px" height="200px" />
            </div>
            <div className="d-none d-sm-block banner-heading">Art for Eco Awareness</div>
          </div>
        </Banner>
        <a className="anchor" id="projects" />
        <Section backgroundStyle="body-darker" backgroundImage="pixabay-1761801_1920x1280.jpg">
          <Row className="pb-5 justify-content-center text-center">
            <Col xs={12} sm={10} md={9}>
              <Block name="projects" className="block-cover fs-4" />
            </Col>
          </Row>
          <div className="pb-5">
            <Image fileName="yomo-dialectic-04.png" />
          </div>
        </Section>
        <Section backgroundStyle="body-darker" backgroundImage="pixabay-4538085-bw_1920x1280.jpg">
          <div className="py-5">
            <ProjectGrid />
          </div>
        </Section>
        <a className="anchor" id="about" />
        <Section backgroundStyle="body-dark" backgroundImage="pixabay-4538080_1920x1280.jpg">
          <Row className="pb-5 justify-content-center text-center">
            <Col xs={12} sm={10} md={9}>
              <Block name="about" className="block-cover fs-5" />
            </Col>
          </Row>
        </Section>
        <a className="anchor" id="support" />
        <Section backgroundStyle="body-dark" backgroundImage="adobe-336613921_1920x1280.jpg">
          <Row className="pb-5 justify-content-center text-center">
            <Col xs={12} sm={10} md={9}>
              <Block name="support" className="block-cover fs-5" />
            </Col>
          </Row>
          <Row className="pb-5 g-4 justify-content-center text-nowrap">
            <Col className="flex-grow-0">
              <Button
                href={'https://opencollective.com/artlab_eco#category-contribute'}
                variant="primary"
              >
                Contribute
              </Button>
            </Col>
            <Col className="flex-grow-0">
              <Button href={'https://opencollective.com/artlab_eco/expenses'} variant="primary">
                Open Ledger
              </Button>
            </Col>
          </Row>
        </Section>
        <a className="anchor" id="contact" />
        <Section backgroundStyle="body-dark" backgroundImage="pixabay-2603990_1920x1280.jpg">
          <Row className="pb-5 justify-content-center text-center">
            <Col xs={12} sm={10} md={9} lg={8}>
              <Block name="contact" className="block-cover fs-5" />
            </Col>
          </Row>
        </Section>
      </Wrapper>
    </GoogleReCaptchaProvider>
  )
}

export default Index
