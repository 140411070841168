import React from 'react'

import Image from './image'

interface Props {
  fileName: string
  variant: string
}

const CardImage = ({ fileName, variant }: Props) => {
  const prefix = 'card-img'
  return <Image fileName={fileName} imgClassName={variant ? `${prefix}-${variant}` : prefix} />
}

export default CardImage
