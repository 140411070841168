import React from 'react'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  className: string
}

const CardText = ({ children }: Props) => {
  const classes = ['card-text', className]
  return <p className={classNames(classes)}>{children}</p>
}

export default CardText
