import React from 'react'
import Masonry from 'react-masonry-css'

import Block from './block'
import Card from './card'

import './project-grid.scss'

const ProjectGrid = () => {
  const breakpointColumns = {
    default: 2,
    992: 1
  }

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumns}
        className="project-grid"
        columnClassName="project-grid-column"
      >
        <Card>
          <Card.Body className="project-item-body">
            <Card.Title className="fs-3 bg-invert">YOMO: Portal</Card.Title>
            <Card.Subtitle className="fs-5 fst-italic pt-2 pb-1">Previewing Now</Card.Subtitle>
            <Block name="yomo-portal" className="fs-5" />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="project-item-body">
            <Card.Title className="fs-3 bg-invert">YOMO: Immersive</Card.Title>
            <Card.Subtitle className="fs-5 fst-italic pt-2 pb-1">Coming Soon</Card.Subtitle>
            <Block name="yomo-immersive" className="fs-5" />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="project-item-body">
            <Card.Title className="fs-3 bg-invert">Leave A Trace</Card.Title>
            <Card.Subtitle className="fs-5 pt-2 pb-1">
              UC Irvine<br/>
              May 2022
            </Card.Subtitle>
            <Block name="leave-a-trace" className="fs-5" />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="project-item-body">
            <Card.Title className="fs-3 bg-invert">In the Liminal Space</Card.Title>
            <Card.Subtitle className="fs-5 pt-2 pb-1">
              Nabla Performance Festival<br/>
              New York, NY<br/>
              May 23, 2022
            </Card.Subtitle>
            <Block name="liminal-space" className="fs-5" />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="project-item-body">
            <Card.Title className="fs-3 bg-invert">Cryosphere Crisis</Card.Title>
            <Card.Subtitle className="fs-5 pt-2 pb-1">
              Winterland Film & Tech Festival<br/>
              Whitefish, MT<br/>
              <em>September 2022</em>
            </Card.Subtitle>
            <Block name="cryosphere-crisis" className="fs-5" />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="project-item-body">
            <Card.Title className="fs-3 bg-invert">Eco Art Showcase</Card.Title>
            <Card.Subtitle className="fs-5 fst-italic py-2">Work in Progress</Card.Subtitle>
            <Block name="showcase" className="fs-5" />
          </Card.Body>
        </Card>
      </Masonry>
    </>
  )
}

export default ProjectGrid
