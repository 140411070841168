import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import './image.scss'

interface Props {
  className: string
  fileName: string
  alt: string
}

const Image = ({ className, fileName, alt, ...other }: Props) => {
  const classes = ['image', className]
  const { imageFiles } = useStaticQuery(
    graphql`
      query {
        imageFiles: allFile(
          filter: { sourceInstanceName: { eq: "images" }, extension: { regex: "/png|jpg/" } }
          limit: 9999
        ) {
          nodes {
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    `
  )

  const imageNode = imageFiles.nodes.find((n) => n.relativePath == fileName)

  return (
    <div className={classNames(classes)}>
      <GatsbyImage backgroundColor="transparent" image={getImage(imageNode)} alt={alt || ''} {...other} />
    </div>
  )
}

export default Image
