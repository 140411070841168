import React from 'react'
import classNames from 'classnames'

import BannerImage from './banner-image'

import './banner.scss'

interface Props {
  children: React.ReactNode
  backgroundImage: string
  backgroundStyle: string
  className: string
  variant: string
}

const Banner = ({ children, backgroundImage, backgroundStyle, className, variant }: Props) => {
  const classes = ['banner', className, variant]
  let contents
  if (backgroundImage?.length > 0) {
    contents = (
      <div className="d-flex flex-column h-100 banner-inner">
        <BannerImage fileName={backgroundImage} />
        {children}
      </div>
    )
  } else {
    if (backgroundStyle?.length > 0) {
      classes.push(`bg-${backgroundStyle}`)
    }
    contents = <div className="d-flex flex-column h-100">{children}</div>
  }
  return <section className={classNames(classes)}>{contents}</section>
}

export default Banner
