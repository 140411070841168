import React from 'react'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  className: string
}

const CardTitle = ({ children, className }: Props) => {
  const classes = ['card-title', 'h5', className]
  return <div className={classNames(classes)}>{children}</div>
}

export default CardTitle
