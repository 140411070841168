import React from 'react'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  className: string
}

const CardSubtitle = ({ children, className }: Props) => {
  const classes = ['card-subtitle', 'h6', className]
  return <div className={classNames(classes)}>{children}</div>
}

export default CardSubtitle
