import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import he from 'he'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Image from './image'

const BlockComponents = {
  Button,
  Card,
  CardBody: Card.Body,
  CardHeader: Card.Header,
  Col,
  Container,
  Image,
  Row,
}

import './block.scss'

interface Props {
  name: string
  className: string
  variant: string
}

const Block = ({ name, className, variant }: Props) => {
  const prefix = 'block'
  const classes = [prefix, className, { [`${prefix}-${variant}`]: variant }]
  const { blockFiles } = useStaticQuery(
    graphql`
      query {
        blockFiles: allMdx(
          filter: { fileAbsolutePath: { regex: "/(/content/blocks/[^/]*.mdx$)/" } }
          limit: 9999
        ) {
          nodes {
            body
            slug
          }
        }
      }
    `
  )
  const blockNode = blockFiles.nodes.find((n) => n.slug == name)

  return (
    <div className={classNames(classes)}>
      <MDXProvider components={BlockComponents}>
        <MDXRenderer>{he.decode(blockNode.body)}</MDXRenderer>
      </MDXProvider>
    </div>
  )
}

export default Block
