import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import './background-image.scss'

interface Props {
  className: string
  fileName: string
  height: string
  alt: string
}

const BannerImage = ({ className, fileName, alt, ...other }: Props) => {
  const classes = ['background-image', className]
  const { bannerImages } = useStaticQuery(
    graphql`
      query {
        bannerImages: allFile(
          filter: { sourceInstanceName: { eq: "banners" }, extension: { regex: "/png|jpg/" } }
          limit: 9999
        ) {
          nodes {
            relativePath
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
    `
  )

  return (
    <div className={classNames(classes)}>
      <GatsbyImage
        image={getImage(bannerImages.nodes.find((n) => n.relativePath == fileName))}
        alt={alt || ''}
        style={{ overflow: 'initial', position: 'initial' }}
        {...other}
      />
    </div>
  )
}

export default BannerImage
