import React from 'react'
import classNames from 'classnames'

import CardBody from './card-body'
import CardFooter from './card-footer'
import CardHeader from './card-header'
import CardImage from './card-image'
import CardSubtitle from './card-subtitle'
import CardText from './card-text'
import CardTitle from './card-title'

interface Props {
  children: React.ReactNode
  className: string
  variant: string
}

const Card = ({ children, className  }: Props) => {
  const classes = ['card', className]
  return <div className={classNames(classes)}>{children}</div>
}

export default Object.assign(Card, {
  Body: CardBody,
  Footer: CardFooter,
  Header: CardHeader,
  Image: CardImage,
  Subtitle: CardSubtitle,
  Text: CardText,
  Title: CardTitle,
})